.leftMenu {
    position : absolute;
    text-align : center;
    background : rgb(249, 249, 249);
    overflow : hidden;
    color : #9D9D9D;
    user-select: none;
}


.left-menu-scene-top {
    
}