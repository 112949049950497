@font-face {
  font-family: "MuktaRegular";
  src: url(/fonts/Mukta-Regular.ttf);
}

@font-face {
  font-family: "MuktaBold";
  src: url(/fonts/Mukta-Bold.ttf);
}


@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url(/fonts/HelveticaNeue-Medium.ttf);
}


td, th {
  border : 1px solid grey;
  min-width: 100px;
}

body {
  margin: 0px;
  padding : 0px;
  background : rgb(255, 255, 255);
  font-family: "MuktaRegular";
  -webkit-tap-highlight-color: transparent;
}

body {
  /* position: fixed; */
  /* overflow: hidden; */
}