#visionContainer {
    /* width : 100vw;
    height : 100vh; */
    /* max-width : 1200px; */
}

.mouseCanvas {
    /* image-rendering: optimizeSpeed;             
    image-rendering: -moz-crisp-edges;          
    image-rendering: -webkit-optimize-contrast; 
    image-rendering: -o-crisp-edges;            
    image-rendering: pixelated;                 
    -ms-interpolation-mode: nearest-neighbor;    */
  }

.loading {
    position : absolute;
    width : 100px;
    height : 100px;
    background-image : url("/loading.gif");
    background-size: 100px;
    z-index : 999999;
    pointer-events: none;
    left : 200px;
    top : 200px;
}

